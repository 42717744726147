.mapclass {
    height: 400px;
    width:400px;
    float: right;
    display: block !important;
    @include media-breakpoint-down(sm){
        display: none!important;
    }
    @include media-breakpoint-down(md){
        display: none!important;
    }
    .pac-container {  //this is a fix for google autocomplete not showing up
        z-index: 10000 !important;
    }
}
.mapclassmodal {
    height: 400px;
    width:300px;
    display: none !important;
    @include media-breakpoint-down(sm){
        display: block!important;
    }
    @include media-breakpoint-down(md){
        display: block!important;
    }
    .pac-container {  //this is a fix for google autocomplete not showing up
        z-index: 10000 !important;
    }
}
.mobile-view{
    display: none!important;
    @include media-breakpoint-down(sm){
        display: block!important;
        
    }
}