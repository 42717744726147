.right-menu
{
    position: fixed;
    right: 0px;
    top:1rem;
    width: 300px;
    float: right;
    //overflow-y: auto;
    background-color: white;
    margin-top: 100px;
    z-index: 50 !important;
    @include media-breakpoint-down(sm){
        display: none;
    }

    // ul
    // {
    //     padding: 5px 0px 5px 25px !important;
    // }

    li
    {
        list-style-type: none;
        margin: 5px;
        border-radius: 5px;
        padding: 4px 3px 4px 3px;
    }
}

.right-menu li:hover
{
    cursor: pointer;
    background-color: lightgray;
    padding-left: 15px;
}


.right-menu1
{
    position: fixed;
    right: 0px;
    top:1rem;
    width: 300px;
    //float: inherit;
    //overflow-y: auto;
    background-color: white;
    margin-top: 100px;
    z-index: 50 !important;
    @include media-breakpoint-down(sm){
        display: none;
    }

    ul
    {
        padding: 5px 0px 5px 25px !important;
    }

    li
    {
        list-style-type: none;
        margin: 5px;
        border-radius: 5px;
        padding: 4px 3px 4px 3px;
    }
}

.right-menu1 li:hover
{
    cursor: pointer;
    background-color: lightgray;
    padding-left: 15px;
}

.left-menu
{
    position: fixed;
    left: 0px;
    top:1rem;
    width: 500px;
    float: right;
    //overflow-y: auto;
    background-color: white;
    margin-top: 100px;
    z-index: 50 !important;
    @include media-breakpoint-down(sm){
        display: none;
    }

    ul
    {
        padding: 5px 0px 5px 25px !important;
    }

    li
    {
        list-style-type: none;
        margin: 5px;
        border-radius: 5px;
        padding: 4px 3px 4px 3px;
    }
}

.mobile-view{
    display: none !important;
    @include media-breakpoint-down(lg){
        display: block !important;
        
    }
}

.webView{
    display: block !important;
    @include media-breakpoint-down(sm){
        display: none !important;
    }
}

#mobileView{
    display: none;
    width: 100%;
    @include media-breakpoint-down(lg){
        display: block !important;
    }
}

