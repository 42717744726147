.view-btn
{
    float :right;
    @include media-breakpoint-down(sm){
        float: left;
    }
}

.evt-link
{
    display: inline;
}

.join-btn
{
    margin-left: 25px;
    @include media-breakpoint-down(sm){
        margin: 0;
    }
}

.signed{
    color: $green;
    font-weight: bolder;
    margin-left: 40px;
    @include media-breakpoint-down(sm){
        float: left;
        margin-left: 5px;
    }
}

.view-signed
{
    color: $green;
}

.evt-img
{
    width: 150px;
    height: 150px;
}

.evt-data li
{
    margin: 5px;
}

.evt-data
{
    @include media-breakpoint-down(sm){
        padding: 0;
        margin: 0;
      }
}

.view-field
{
    font-weight: bolder;
    font-size: larger;
}