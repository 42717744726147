.my-act-heading
{
    font-size: xx-large;
    font-weight: 500;
}

.my-act
{
    li{
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.my-act li:hover{
    cursor: pointer;
}
