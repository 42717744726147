table{
    width : 90%;
    overflow-x: scroll !important;
    border : 0.2px solid lightgray;
    font-family: sfpro,sans-serif;
    font-size: 12px;
  }

.table{
  width: 100%;
  overflow-x:scroll ;
}
