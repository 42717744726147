.control-label
{
    float: right;
    @include media-breakpoint-down(sm){
        float: left;
        margin-right: none;
        padding-top: 0px;
      }
}

.required
{
    color: $red;
}

.form
{
    background-color: white;
    padding: 0 !important;
    margin-left: 10%;
    margin-right: 10%;
    @include media-breakpoint-down(sm){
        margin-left: 0;
        margin-right: 0;
    }
}

.form-heading
{
    text-align: center;
}

.header
{
    background-color: $headers;
}

.navbar-brand
{
    color: $white!important;
}

.navbar-toggler-icon
{
 color : #fff!important;
}

.search-icon
{
 color : $white!important;
}

.search-bar
{
    margin-top: 6px;
}

.connct-btn {
    border-radius: 15px;
    background-color: transparent;
    border:1px solid blue
  }
  
  .connct-ul{
    height: 35px;
  }
